(function ($) {
  $(document).ready(function () {

    $('a[href^="#"]:not(a.menu-trigger,#back_to_top a)').on('click', function (e) {

      var $href = $(this).attr('href'),
        $tgt = $($href == "#" || $href == "" ? 'html' : $href),
        $pos = $tgt.offset().top,
        $correctH = parseInt($('.header_top').outerHeight() + 10);
      $("html, body").animate({
        scrollTop: $pos - $correctH
      }, 500);
      e.preventDefault();

    });


    var mql = window.matchMedia("(min-width : 1700px)");
    mql.addListener(menuClose);
    menuClose(mql);

    function menuClose(mql) {
      if (mql.matches) {
        $("#menu").removeClass('menu_open');
        $('.menu-trigger').removeClass('triggeractive');
      }
    }

    $('.slider').slick({
      arrows: false,
      autoplay: true,
      fade: true,
      speed: 300,
      pauseOnHover: false
    });
    


    // gmap iframe
    $('.gmap').click(function () {
      $('.gmap iframe').css("pointer-events", "auto");
    });

  });



  
  
})(jQuery);

window.addEventListener('load',function(){
  document.getElementById('loading').classList.add('finished');
})



window.addEventListener('DOMContentLoaded', function () {
  
  
  
  new ScrollHint('.table__wrap', {
    scrollHintIconAppendClass: 'scroll-hint-icon-white', // white-icon will appear
    i18n: {
      scrollable: 'スクロールできます'
    }
  });
  
  
  //set noopener
  
  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links,function(link){
    
    if(link.hasAttribute('rel') === false){
      link.setAttribute('rel','noopener');
    }

  })
  



  //hmb
  
  const hmbTrigger = document.querySelector('.hmb__trigger');
  const body = document.body;
  const menuBg = document.querySelector('.menu__bg');
  const menuList = document.querySelector('.menu__list');
  const menuWrap = document.querySelector('.menu__wrap');
  const changingList = [hmbTrigger,body,menuBg,menuList,menuWrap];
  
  const globalnaviInner = document.querySelector('.globalnavi__inner');
  

  function headerReduce(){
    var current = window.pageYOffset;

    if(current > 100){
      globalnaviInner.classList.add('headerReduce')
      menuList.classList.add('headerReduce')
    }else{
      globalnaviInner.classList.remove('headerReduce')
      menuList.classList.remove('headerReduce')
    }
  }
  
  window.addEventListener('scroll', headerReduce);
  window.addEventListener('load', headerReduce);
  
  
  
  
  hmbTrigger.addEventListener('click',function() {
    changingList.forEach((elm)=>{
      elm.classList.toggle('menu--open');
    })
  });

  function menuAction(){
    if( window.innerWidth < 1261){
      
      //メニュー開いているときにメニューリンク以外クリックしたら閉じる
      document.querySelector('.menu__wrap').addEventListener('click',function(){
        changingList.forEach((elm)=>{
          elm.classList.remove('menu--open');
        })
      });

      //メニューリンク近辺は閉じない
      menuList.addEventListener('click', function(e){
        e.stopPropagation();
      });

    }
  }
  
  
  menuAction();
  
  window.addEventListener('resize', function(){
    if( window.innerWidth >= 1260){
      changingList.forEach((elm)=>{
        elm.classList.remove('menu--open');
      })
    }
  })
  

  
  //背景のライン
  /*var bodyLine = document.querySelector('.body--line');
  var content = document.querySelector('.content');
  var timer;

  function bgHeight(){
    var contentHeight = content.clientHeight;
    bodyLine.style.height = contentHeight + 'px';
  }

  bgHeight()


  window.addEventListener('resize',function(){
    
    clearTimeout(timer)
    
    timer = setTimeout(function(){
      bgHeight();
    },300);
  })*/

  

 //Gナビにsns電話追加
  var snsList = document.getElementById('sns_list');

  //var snsHtml = snsList.innerHTML;

  var addLinkArr= [ snsList];
  
  addLinkArr.forEach(function(elm){
    var li = document.createElement('li');
    li.innerHTML = elm.innerHTML;
    if(elm === snsList){
      li.classList.add('sns');
    }
    
    menuList.appendChild(li);
  })
  

  //li.classList.add('sns');
  //li.innerHTML = snsList
  

  

  /*menuWrap.addEventListener('click',function(e){
    e.stopPropagation();
  })*/
  objectFitImages('.ofi');
  

  
});





window.addEventListener('load',function(){
  const loading = document.querySelector('#loading');
  loading.classList.add('finished');
})



//fixed

const menu = document.querySelector('#menu');
const mainvisual = document.querySelector('.mainvisual');


//parallax
const parallaxElements = document.querySelectorAll('.parallax');

function parallax(){
  let scr = window.pageYOffset + window.innerHeight;

  [].forEach.call(parallaxElements,function(elm){
    const elmPos = elm.getBoundingClientRect().top + window.pageYOffset;
    if(scr - 100 > elmPos){

      elm.classList.add('show');
    }else{
      elm.classList.remove('show');
    }
  })
}

window.addEventListener('scroll',parallax)
window.addEventListener('load',parallax)
